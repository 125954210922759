<template>
  <div class="plan-change-step3-container">
    <div class="plan-content">
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M44 22.16V24C43.9975 28.3128 42.601 32.5093 40.0187 35.9636C37.4363 39.4179 33.8066 41.9449 29.6707 43.1678C25.5349 44.3906 21.1145 44.2438 17.0689 42.7491C13.0234 41.2545 9.56931 38.4922 7.22192 34.8741C4.87453 31.256 3.75958 26.9761 4.04335 22.6726C4.32712 18.3691 5.99441 14.2726 8.79656 10.9941C11.5987 7.71561 15.3856 5.43074 19.5924 4.48026C23.7992 3.52979 28.2005 3.96465 32.14 5.71997" stroke="#FF6600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M44 8L24 28.02L18 22.02" stroke="#FF6600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      <template v-if="service.plan.trial">
        <div class="h6" style="margin:12px 0 8px 0">결제 완료되었습니다.</div>
        <div class="body2 sub3">플랜 구매가 완료되었습니다.<br>
          변경된 서비스 결제 정보와 이용 정보를 확인하세요.</div>
      </template>
      <template v-else>
        <div class="h6" style="margin:12px 0 8px 0">플랜변경이 완료되었습니다.</div>
        <div class="body2 sub3">플랜 변경이 완료되었습니다.<br>
          변경된 서비스 결제 정보와 이용 정보를 확인하세요. </div>
      </template>

      <div class="box-account-price" style="margin-top:32px">
        <div v-for="(item,idx) in list" :key="`account-${idx}`"
          class="item-price">
          <div class="text-left">
            <div class="body4-medium">{{ item.label }}
              <span v-if="item.date_unit_txt" class="primary" style="margin-left:4px">{{ item.date_unit_txt }}</span>
            </div>
            <div class="body5 sub3" v-if="item.desc">{{ item.desc }}</div>
          </div>
          <div class="body4 text-right">
            <span><span class="body4-bold">{{ item.price|currencyNum }}</span>원</span>
            <span class="sub3" v-if="item.date_unit>0">/{{ item.date_unit === 1 ? '월' : item.date_unit + '개월' }}</span>
          </div>
        </div>
        <div v-if="remainPrice > 0"
             class="item-price primary">
          <div class="body4">변경 전, 플랜/호스팅 잔여금액</div>
          <div><span class="price">{{ remainPrice|currencyNum }}</span>원</div>
        </div>
      </div>

      <div v-if="remainPrice>0"
           class="body5 box-blue flex-align">
        <i class="material-icons-round">warning</i>
        <div>변경할 플랜 금액에서 변경 전 플랜의 잔여금액을 차감하고 결제됩니다.<br>
          결제 즉시 플랜이 변경되며, 새로운 결제일로 설정됩니다.<br>
          <span class="weight-600">다음 결제일({{ nextDate }})부터 플랜금액 {{ totalPrice|currency }}이 결제됩니니다.</span></div>
      </div>
      <div v-else-if="!service.plan.trial && !isUpgrade" class="body5 box-blue flex-align">
        <i class="material-icons-round">warning</i>
        <div>{{ nextDate }}에 결제가 시작되며 플랜주기별로 자동으로 갱신됩니다.<br>
          이후 변경된 플랜의 서비스 이용이 가능합니다.</div>
      </div>

      <div class="button-group">
        <button class="button is-primary" @click="clickInfo">서비스 결제 정보</button>
        <button class="button" @click="clickClose">닫기</button>
      </div>
    </div>

  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: "PlanChangeStep3",
    props: {
      service: {
        type: Object
      },
      accounts: {
        type: Array
      }
    },
    created() {
    },
    computed: {
      totalPrice() {
        return this.accounts.reduce((a,c) => a + c.price, 0);
      },

      isUpgrade() {
        let plan = this.accounts.find(i => i.type === 'plan');
        return (
          // 등급이 높거나
          (plan.grade > this.service.plan.grade) ||
          // 같은 등급인데, 결제주기가 더 길어지는 경우
          (plan.grade === this.service.plan.grade &&
            plan.date_unit !== undefined &&
            plan.date_unit > this.service.plan.date_unit)
        )
      },
      nextDate() {
        let plan = this.accounts.find(i => i.type === 'plan');
        return this.isUpgrade ? moment().add('months', plan.date_unit).format('YYYY.MM.DD') :
          moment(this.service.plan.next_date).format('YYYY.MM.DD');
      },
      remainPrice() {
        if(this.service.plan.trial || !this.isUpgrade) {
          return 0;
        }
        const plan_start = moment(this.service.plan.use_start_date).format('YYYY-MM-DD');
        const plan_price = moment().diff(plan_start, 'days') *
          (this.service.plan.price / this.service.plan.date_unit / 30);

        const hosting_start = moment(this.service.hosting_info.use_start_date).format('YYYY-MM-DD');
        const hosting_price = moment().diff(hosting_start, 'days') *
          (this.service.hosting_info.price / this.service.hosting_info.date_unit / 30);

        return parseInt(plan_price + hosting_price);
      },
      list() {
        return this.accounts.map(i => {
          if(i.label.indexOf('앱포팅')>-1) {
            i.label = '앱포팅 등록 및 대행';
            i.desc = '앱포팅작업과 관련해서는 별도안내가 진행될 예정입니다.'
          }
          return i;
        });
      }
    },
    methods: {
      clickInfo() {
        this.$emit('done', 'info');
      },
      clickClose() {
        this.$emit('done', 'close');
      }
    },
    data() {
      return {
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .plan-change-step3-container
    padding 36px 0 112px 0
    display flex
    justify-content center
    color $main

  .plan-content
    width 480px
    text-align center

  .box-account-price
    border-top 1px solid $main
    .item-price
      padding 12px 0
      border-bottom 1px solid $gray2
      display flex
      justify-content space-between

  .button-group
    margin-top 32px
    display flex
    justify-content center
    gap 16px
    .button
      width 200px
      height 48px !important
      border-radius 8px
      font-size 16px !important
      font-weight 500

  .box-blue
    color #0063F7
    padding 12px 16px
    gap 16px
    margin-top 16px
    text-align left
    background-color #F2F7FF
    border-radius 8px
</style>
