<template>
  <div class="panel-container">

    <div class="box-trial-waning" v-if="card.service_order.trial && card.service_order.d_day <= 7 && card.status === 9">
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7173 0.991484C10.9541 -0.330495 9.04594 -0.330494 8.2827 0.991485L0.268626 14.8723C-0.49462 16.1943 0.459439 17.8467 1.98593 17.8467H18.0141C19.5406 17.8467 20.4946 16.1942 19.7314 14.8723L11.7173 0.991484ZM9.91457 10.9113C10.1775 10.9113 10.4297 10.8068 10.6157 10.6209C10.8016 10.4349 10.9061 10.1827 10.9061 9.91979V5.95385C10.9061 5.6909 10.8016 5.43871 10.6157 5.25277C10.4297 5.06683 10.1775 4.96237 9.91457 4.96237C9.65161 4.96237 9.39942 5.06683 9.21349 5.25277C9.02755 5.43871 8.92309 5.6909 8.92309 5.95385L8.92309 9.91979C8.92309 10.1827 9.02754 10.4349 9.21348 10.6209C9.39942 10.8068 9.65161 10.9113 9.91457 10.9113ZM9.21062 14.5897C9.30491 14.6799 9.4161 14.7507 9.53781 14.7979C9.77919 14.8971 10.0499 14.8971 10.2913 14.7979C10.413 14.7507 10.5242 14.6799 10.6185 14.5897C10.7061 14.4933 10.7765 14.3826 10.8267 14.2625C10.8822 14.1448 10.9094 14.0158 10.9061 13.8857C10.9068 13.7552 10.8818 13.6259 10.8325 13.5051C10.7831 13.3843 10.7104 13.2744 10.6185 13.1818C10.5222 13.0942 10.4115 13.0238 10.2913 12.9736C10.1411 12.9119 9.97806 12.888 9.81647 12.904C9.65487 12.9201 9.4997 12.9756 9.36458 13.0657C9.22946 13.1558 9.11854 13.2777 9.04156 13.4207C8.96458 13.5636 8.92389 13.7233 8.92309 13.8857C8.92674 14.1482 9.02944 14.3997 9.21062 14.5897Z" fill="#FF3B3B"/>
      </svg>
      <div class="body4">무료체험이 {{ card.service_order.d_day }}일 남았습니다.
        <span class="body4-bold">플랜을 결제하지 않으시면 서비스가 삭제됩니다.</span>
      </div>
    </div>

    <div class="flex-between" style="align-items: flex-start">
      <div class="main position-relative">
        <div class="flex-align" style="gap:8px">
          <div class="subtitle3">{{ card.service_name }}</div>
          <div v-if="card.status === 5" class="tag tag-status-5">{{ statusTxt }}</div>
          <div class="tag tag-trial" v-else-if="card.service_order.trial">무료체험</div>
          <div v-else-if="card.status === 9" class="tag" :class="statusClass">{{ statusTxt }}</div>
          <div v-else-if="card.status > 9" class="tag tag-status-stop">{{ statusTxt }}</div>
          <div v-if="card.status === 5"
            class="body5 sub3">*개설이 완료되면 메일로 알려드립니다.(예상 소요시간 15~20분)</div>
          <div v-if="card.skin_inquiry && card.skin_inquiry.status === 2"
               class="body5 sub3">*작업이 완료되면 메일로 알려드립니다.</div>
        </div>
        <div v-if="card.status === 5"
          class="body4 sub2 margin-top-8">{{ card.domain }}</div>
        <a v-else-if="[9,10].indexOf(card.status) > -1"
          :href="card.domain" target="_blank">
          <div class="body4 sub2 margin-top-8">{{ card.domain }}</div>
        </a>
        <div v-else-if="card.status === 99"
          class="body4 sub2 margin-top-8">서비스 접근을 위해서는 플랜 결제가 필요합니다.</div>
        <div v-else-if="card.status === 100"
             class="body4 sub2 margin-top-8">서비스가 삭제되어 접근이 불가합니다.</div>

        <div class="box-disabled" v-if="card.status>9"></div>
      </div>
      <!--<button v-if="card.status === 5 && card.service_type === 5" @click="routerPush('/inquiry_reg?type_id=21')"
        class="button button-market is-primary-light">스킨 수정문의</button>-->
      <div v-if="card.status === 9" class="flex" style="gap:12px">
        <div class="flex-align" style="gap:12px" v-if="card.service_type === 5 && !card.service_order.trial && card.skin_inquiry">
          <div v-if="card.not_read>0" class="body5 error">*추가수정 상세를 확인해주세요.</div>
          <button class="button button-inquiry is-primary-light flex-align" style="gap:4px" @click="clickSkinInquiry">
            <svg-icon icon="u_edit-alt" color="primary"></svg-icon>
            <div>추가수정 요청</div>
            <span v-if="card.not_read>0" class="circle-new">{{ card.not_read }}</span>
          </button>
        </div>
        <button v-if="vReviewModal"
          class="button button-market is-gray" @click="clickReviewReg">후기작성</button>
        <button v-if="card.service_type !== 5"
          class="button button-market is-gray" @click="clickButton('market')">앱마켓</button>
        <button class="button button-market is-primary" @click="clickButton('admin')">관리</button>
      </div>
      <button v-if="card.status > 9" class="button button-market is-gray2" @click="clickButton('plan')">관리</button>
    </div>

    <template v-if="card.status>5">
      <div class="divider"></div>
      <div class="flex-align position-relative">
        <div class="flex-1 divider-left">
          <template v-if="card.service_type===5">
            <div class="body4 sub2 margin-bottom-8">스킨 옵션</div>
            <div v-if="card.service_order.trial" class="flex-align">
              <div class="h8 error">무료체험 D-{{ card.service_order.d_day|currencyNum }}</div>
              <button class="button is-error" @click="clickSkin">스킨 결제</button>
            </div>
            <div v-else-if="card.terminate_expect_date" class="h8 error">스킨 해지 ({{ datesFormat(card.terminate_expect_date,'date_3') }}까지 이용가능)</div>
            <div v-else class="h8 sub">{{ card.skin_inquiry ? '수정개발 추가요청' : '스킨 그대로' }}</div>
          </template>
          <template v-else>
            <div class="body4 sub2 margin-bottom-8">멤버십 / 플랜</div>
            <div v-if="card.service_order.trial" class="flex-align">
              <div class="h8 error">무료체험 D-{{ card.service_order.d_day|currencyNum }}</div>
              <button class="button is-error" @click="clickPlan">플랜 결제</button>
            </div>
            <div v-else-if="card.terminate_expect_date" class="h8 error">플랜 해지 ({{ datesFormat(card.terminate_expect_date,'date_3') }}까지 이용가능)</div>

            <div v-else class="flex-align">
              <div class="h8">{{ card.membership_name }} ({{ monthly }} 정기결제)</div>
              <div v-if="card.status === 9" @click="clickPlan"
                   class="unselect primary body4 flex-align" style="margin-left:8px">
                플랜 변경 <img src="/static/icon/u_arrow-up-right.svg" class="ic-up-right svg-primary"></div>
            </div>
          </template>
        </div>
        <div class="flex-1" style="padding-left:40px">
          <div class="body4 sub2 margin-bottom-8">앱포팅</div>
          <button-app-porting :service="card" :trial="card.service_order.trial"
                              @plan="clickButton('planModal')"
                              @android="clickButton('android')"
                              @ios="clickButton('ios')"></button-app-porting>
        </div>

        <div class="box-disabled" v-if="card.status>9"></div>
      </div>
    </template>
    <div class="box-account body4" v-if="card.account.next_price>0 && card.status <= 9 && !card.terminate_expect_date">
      <div class="flex-1 flex-between divider-left">
        <div class="sub2">다음 결제 예정일
          <span style="margin-left:16px">{{ datesFormat(card.account.next_date, 'date_3') }} ({{ card.account.next_price|currency }})</span>
        </div>
        <div class="primary unselect" @click="clickButton('plan')">결제내역</div>
      </div>
      <div class="flex-1 flex-between" style="padding-left:40px">
        <div class="sub2">결제수단
          <span style="margin-left:16px">{{ card.account.bill.num + ' / ' + card.account.bill.card_name }}</span>
        </div>
        <div class="primary unselect" @click="v.bill=true">변경</div>
      </div>
      <next-date-account-modal ref="accountModal" :account="card.account"></next-date-account-modal>
      <user-billing-modal v-if="v.bill" radio @close="v.bill=false" @select="onSelectBill"></user-billing-modal>
    </div>
    <plan-change-modal v-if="v.plan" :serviceId="card.id"
                       @done="onDone"
                       @close="v.plan=false"></plan-change-modal>
    <trial-review-modal ref="reviewModal" :serviceInfo="card"
                        v-if="vReviewModal"></trial-review-modal>

    <skin-inquiry-modal ref="skinInquiryModal" :service="card"></skin-inquiry-modal>
  </div>
</template>

<script>
  import ButtonAppPorting from "./ButtonAppPorting";
  import NextDateAccountModal from "../modal/NextDateAccountModal";
  import UserBillingModal from "../modal/UserBillingModal";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import PlanChangeModal from "../modal/PlanChangeModal";
  import TrialReviewModal from "../modal/TrialReviewModal";
  import SvgIcon from "./SvgIcon";
  import SkinInquiryModal from "../modal/SkinInquiryModal";
  export default {
    name: "CardMyServicePc",
    mixins: [
      UserAPIMixin
    ],
    components: {
      SkinInquiryModal,
      SvgIcon, TrialReviewModal, PlanChangeModal, UserBillingModal, NextDateAccountModal, ButtonAppPorting},
    props: {
      card: {
        type: Object
      }
    },
    data() {
      return {
        v: {
          bill: false,
          plan: false
        },
        popupMarket: undefined,
      }
    },
    computed: {
      vReviewModal() {
        return this.card.status === 9 && this.card.service_order && !this.card.service_order.review;
      },
      monthly() {
        return `${this.card.service_order.date_unit}개월`;
      },
      statusClass() {
        let name = 'tag-status-9';
        if(this.card.skin_inquiry && this.card.skin_inquiry.status < 3) {
          name = `tag-skin-status-${this.card.skin_inquiry.status}`;
        }
        return name;
      },
      statusTxt() {
        const status = {
          5: '서비스 개설중',
          9: '이용 중',
          10: '관리자 정지',
          99: '전체 정지',
          100: '이용 해지',
        };

        if(this.card.service_type === 5) {
          if(this.card.status === 9 && this.card.skin_inquiry && this.card.skin_inquiry.status < 3) {
            return {
              0: '수정요청 확인중',
              1: '수정요청 결제대기',
              2: '수정요청 작업중',
              3: '수정완료',
              4: '수정완료'
            }[this.card.skin_inquiry.status];

          } else {
            return status[this.card.status];
          }
        } else {
          return status[this.card.status];
        }

      }
    },
    methods: {
      onDone() {
        this.v.plan = false;
        this.$emit('done');
      },
      clickSkin() {
        if(this.card.admin.id === 0) {
          alert('관리 버튼을 눌러서 관리자 로그인 후 이용가능합니다.');
          return;
        }
        let admin = this.card.admin;

        let url = `/theme_detail?id=${this.card.service_order.products[0].id}`;
        if(admin) {
          url += `&i=${admin.id}&tn=${admin.token}`+
          `&key=${this.card.key}`+
          `&s=${this.card.id}`;
        }
        this.routerPush(url);
      },
      clickPlan() {
        this.$store.commit('setAdmin', this.cloneItem(this.card.admin));
        this.v.plan = true;
      },
      onMessageMarket(e) {
        if(e.data.close) {
          this.popupMarket.close();
          this.removeMessageEvent();
          if(e.data.adminRedirect) {
            setTimeout(() => {
              window.open(`https://${this.card.key}.launchpack.co.kr/office/admin/dashboard?plugin_key=${e.data.adminRedirect}`);
            }, 300);
          } else if(e.data.addPlugin) {
            let param = `add_plugin=${e.data.addPlugin}`;

            // 지정 경로로 이동시킬 플러그인
            const plugin_move_page = ['point_coupon'];
            if(plugin_move_page.indexOf(e.data.addPlugin)>-1) {
              param = 'front_path=my_coupon';
            }

            window.open(`https://${this.card.key}.launchpack.co.kr/developers/ui/builder?${param}`, '_blank');
          }
        }
      },
      addMessageEvent() {
        window.addEventListener('message', this.onMessageMarket);
      },
      removeMessageEvent() {
        window.removeEventListener('message', this.onMessageMarket);
      },
      clickReviewReg() {
        this.card.admin.service_id = this.card.id;
        this.$store.commit('setAdmin', this.cloneItem(this.card.admin));
        this.$refs.reviewModal.open();
      },
      clickSkinInquiry() {
        this.card.admin.service_id = this.card.id;
        this.$store.commit('setAdmin', this.cloneItem(this.card.admin));
        this.$refs.skinInquiryModal.open();
      },
      clickButton(type) {
        let types = {
          admin : '/dashboard',
          plan: '/service/plan'
        };
        if(type === 'planModal') {
          this.clickPlan();
        }
        else if(['market','android','ios'].indexOf(type)>-1) {
          if(this.card.admin.id === 0) {
            alert('관리 버튼을 눌러서 관리자 로그인 후 이용가능합니다.');
          } else {
            if(this.card.service_type === 5) {
              window.open(`${this.card.domain}/office/admin/launchpack/inquiry/customer/create?cat=10&app_porting=${type}`,'_blank');
            }
            else {
              let url = `/app_market?i=${this.card.admin.id}&tn=${this.card.admin.token}&s=${this.card.id}&parent=home`;

              if(type === 'android') {
                url += '&detail=177';
              } else if(type === 'ios') {
                url += '&detail=156';
              }

              let width = 1200;
              let height = 760;
              /*if(window.innerHeight < height) {
                height = window.innerHeight - 80;
              }
              if(window.innerWidth < width) {
                width = window.innerWidth - 200;
              }*/
              this.popupMarket = window.open(url, 'appMarket', `width=${width}, height=${height}, top=0, left=0, menubar=no, toolbar=no, titlebar=yes`);
              this.addMessageEvent();
            }
          }
        } else {
          window.open(`${this.card.domain}/office/admin${types[type]}`,'_blank');
        }
      },
      onSelectBill(item) {
        this.request.user.post(`launchpack/card_bill/service/${this.card.id}`, {'user_bill': item.id}).then(res=>{
          if(res.status === 200) {
            this.card.account.bill.card_name = item.card_name;
            this.card.account.bill.num = item.num;
          }
        });
      },
    }
  }
</script>


<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .panel-container
    padding 24px 32px
    margin-bottom 16px
  .panel-container:last-child
    margin-bottom 0

  .tag
    border-radius 4px
    padding 4px 8px
    font-size 14px
    line-height 22px

  .tag-status-5
    background-color $gray3
    color $sub2
  .tag-trial
    background #FFF0F0
    color $error
  .tag-status-9
    background-color $primary-light2
    color $primary
  .tag-status-stop
    background-color $sub4
    color white

  .tag-skin-status-0
    background-color $gray2
    color $sub2
  .tag-skin-status-1
    background-color $error50
    color $error
  .tag-skin-status-2
    background-color $subcolor50
    color $subcolor2

  .button-market
    width 120px
    height 36px

  .button.is-error
    width 102px
    height 32px
    margin-left 8px

  .divider
    width 100%
    height 1px
    background-color $gray2
    margin 16px 0 20px 0

  .flex-1
    flex 1
  .divider-left
    padding-right 40px
    border-right 1px solid $gray2

  .box-trial-waning
    background-color $error50
    color $error
    padding 10px 12px
    border-radius 8px
    display flex
    align-items center
    gap 10px
    margin-bottom 20px

  .box-disabled
    position absolute
    width 100%
    height 100%
    top 0
    left 0
    background-color rgba(255, 255, 255, 0.5)
    z-index 1

  .box-account
    margin-top 20px
    background-color $gray4
    border-radius 8px
    padding 20px 24px
    display flex
    align-items center

  .ic-up-right
    width 16px
    height 16px
    margin-left 4px

  .circle-new
    width 16px
    height 16px
    line-height 16px
    background-color $error
    border-radius 100%
    text-align center
    color white
    font-size 12px
    font-weight 500
  .button-inquiry
    padding 10px 20px
    height 36px
</style>
